<template>
  <div class="table-block_table--row--item percent-bar">
    <div
      class="percent-bar__item percent-bar__item-node"
      :class="{
        'percent-bar__item-red': nodePercentage >= 0 && nodePercentage <= 20,
        'percent-bar__item-green': nodePercentage >= 21 && nodePercentage <= 80,
        'percent-bar__item-gray': nodePercentage >= 81 && nodePercentage <= 100

      }"
    >
      {{ nodePercentage }}%
    </div>
    <div
      class="percent-bar__item percent-bar__item-market"
      :class="{
        'percent-bar__item-red': exchangePercentage >= 0 && exchangePercentage <= 20,
        'percent-bar__item-green': exchangePercentage >= 21 && exchangePercentage <= 80,
        'percent-bar__item-gray': exchangePercentage >= 81 && exchangePercentage <= 100
      }"
    >
      {{ exchangePercentage }}%
    </div>
  </div>
</template>

<script>
export default {
  props: {
    token: {
      type: String,
      required: true
    },
    chartDataMounted: {
      type: Object,
      required: true
    }
  },
  computed: {
    nodeBalanceAvailable() {
      if (this.token === 'USDT_ETH' || this.token === 'USDT_TRX') {
        return this.chartDataMounted.node.USDT_ETH.balanceUsdt + this.chartDataMounted.node.USDT_TRX.balanceUsdt
      } else if (this.token === 'USDC_ETH' || this.token === 'USDC_TRX') {
        return this.chartDataMounted.node.USDC_ETH.balanceUsdt + this.chartDataMounted.node.USDC_TRX.balanceUsdt
      } else if (this.token === 'USDT' || this.token === 'USDC') {
        return 0
      } else {
        if (!this.chartDataMounted.node[this.token]) {
          return null
        }
        return this.chartDataMounted.node[this.token].balanceUsdt;
      }
    },
    exchangeBalanceAvailable() {
      if (this.token === 'USDT_ETH' || this.token === 'USDT_TRX' || this.token === 'USDC_ETH' || this.token === 'USDC_TRX') {
        return 0;
      } else {
        return this.chartDataMounted.exchange[this.token].balanceUsdt;
      }
    },
    totalBalanceAvailable() {
      return this.nodeBalanceAvailable + this.exchangeBalanceAvailable;
    },
    nodePercentage() {
      return Math.round((this.nodeBalanceAvailable / this.totalBalanceAvailable) * 100);
    },
    exchangePercentage() {
      return 100 - this.nodePercentage;
    }
  }
};
</script>

<style lang="scss">
.percent-bar {
  display: flex;
  flex-direction: row;
  gap: 2px;
  border-radius: 4px;
  overflow: hidden;

  &__item {
    width: 64px;
    height: 28px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: grid;
    place-items: center;

    &-green {
      color: #00B18B;
      background: rgba(0, 177, 139, 0.12);
    }

    &-red {
      color: #FF603D;
      background: rgba(255, 96, 61, 0.12);
    }

    &-gray {
      color: #9FA2B4;
      background: rgba(159, 162, 180, 0.12);
    }
  }
}
</style>
