<script>
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import CopyNotification from "@/pages/notification/CopyNotification.vue";
import {mapGetters} from "vuex";
import CoinsSvg from "@/pages/balance/CoinsSvg.vue";
import HoverText from "@/pages/coins/partials/hoverText.vue";

export default {
  name: 'BalanceMirrorAddress',
  components: {HoverText, CoinsSvg, CopyNotification},
  props: {
    data: {
      type: Array,
      default: null
    },
    coins: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      nodasBalances: null,
      isLoading: true,
      copyText: '',
      isSuccessCopy: true,
      currentIndex: null,
      isMobileDevice: this.isMobile(),
      tableTh: [
        {
          name: 'Network',
          sort: '',
          key: 'network',
          isSortable: false
        },
        {
          name: 'Node address',
          sort: '',
          key: 'nodeAddress',
          isSortable: false
        },
        {
          name: 'Balance',
          sort: '',
          key: 'balance',
          isSortable: false
        },
      ],
      tableThMobile: [
        {
          name: 'Network',
          sort: '',
          key: 'network',
          isSortable: false
        },
        {
          name: 'Balance',
          sort: '',
          key: 'balance',
          isSortable: false
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      UserProfileData: 'getUserProfileData'
    }),
    sortedNodasBalances() {
      const tokens = Object.values(this.nodasBalances);

      tokens.sort((a, b) => {
        if (a.token === 'BTC') {
          return -1;
        } else if (a.token === 'ETH' && b.token !== 'BTC') {
          return -1;
        } else if (a.token === null) {
          return -1;
        } else {
          return a.token.localeCompare(b.token);
        }
      });

      return tokens;
    }
  },
  mounted() {
    this.getData();
    window.addEventListener('resize', this.updateDeviceType);

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateDeviceType);
  },
  methods: {
    isMobile() {
      return window.innerWidth <= 1079;
    },
    updateDeviceType() {
      this.isMobileDevice = this.isMobile();
    },
    onCopyAddress() {
      this.copyText = 'Copied to clipboard!';

      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
    getData() {
      this.nodasBalances = this.data
      this.tokensData = this.extractTokens(this.nodasBalances);
      this.isLoading = true
    },
    extractTokens(data) {
      let tokens = [];

      for (let token in data) {
        if (!tokens.includes(token)) {
          tokens.push(token);
        }
      }

      if (tokens.includes("BTC")) {
        const btcIndex = tokens.indexOf("BTC");
        tokens.splice(btcIndex, 1);
        tokens.unshift("BTC");
      }
      if (tokens.includes("ETH")) {
        const ethIndex = tokens.indexOf("ETH");
        tokens.splice(ethIndex, 1);
        tokens.splice(1, 0, "ETH");
      }

      return tokens;
    },
    amountToFixed(val, token) {
      let numberToFixed

      if (!token) {
        return 0
      }

      if (token.toUpperCase() === 'BTC' || token.toUpperCase() === 'ETH') {
        numberToFixed = 4;
      } else if (token.toUpperCase() === 'XMR' || token.toUpperCase() === 'LTC') {
        numberToFixed = 2;
      }  else {
        numberToFixed = 2;
      }

      if (val !== undefined && val !== null) {
        const fixedVal = val.toFixed(numberToFixed);

        if (val >= 10000) {
          return parseFloat(fixedVal).toLocaleString('en-US', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 5
          });
        } else {
          return fixedVal
        }
      }
    },
    copyTextToClipboard(text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          this.onCopyAddress();
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    },
    mirrorWithdraw(el) {
      this.$store.commit('setMirrorWithdraw', el)
    }
  }
}


</script>

<template>
  <div class="table_node-balances main-block">
    <div class="table-block">
      <div class="table-block-header">
        <div class="table-block-header-title">
          Node balances
        </div>
      </div>
      <div
        v-if="nodasBalances"
        class="table-block-table"
      >
        <table class="table">
          <thead>
          <tr
            v-if="!isMobileDevice"
            class="table-headers"
          >
            <th
              v-for="(item, index) in tableTh"
              :key="index"
              scope="col"
              :class="{
                  'table-first': item.key === 'network',
                  'table-balance': item.key === 'balance'
                }"
            >
              <div
                class="text"
              >
                {{ item.name }}
              </div>
            </th>
            <th class="table-edit" />
          </tr>
          <tr
            v-if="isMobileDevice"
            class="table-headers"
          >
            <th
              v-for="(item, index) in tableThMobile"
              :key="index"
              scope="col"
              :class="{
                  'table-first': item.key === 'network',
                  'table-balance': item.key === 'balance'
                }"
            >
              <div
                class="text"
              >
                {{ item.name }}
              </div>
            </th>
            <th class="table-edit" />
          </tr>
          </thead>
          <tbody>
          <template
            v-for="(item, index) in sortedNodasBalances"
            :key="index"
          >
            <tr
              class="table-row"
            >
              <td
                class="table-cell table-first"
              >
                <div class="token">
                  <div class="icon">
                    <CoinsSvg
                      :coin="item.token"
                      :coins-list="coins"
                    />
                    <div
                      v-if="item.token === 'USDT'"
                      class="icon-network"
                      :class="{
                          'icon-network_trx': item.network === 'TRX',
                          'icon-network_eth': item.network === 'ETH',
                        }"
                    >
                      {{ item.network }}
                    </div>
                    <div
                      v-if="item.token === 'USDC'"
                      class="icon-network"
                      :class="{
                          'icon-network_trx': item.network === 'TRX',
                          'icon-network_eth': item.network === 'ETH',
                        }"
                    >
                      {{ item.network }}
                    </div>
                  </div>
                  {{ item.token ? item.token : 'Profit' }}
                </div>
              </td>
              <td
                v-if="!isMobileDevice"
                class="table-cell table-node_address"
                @click="copyTextToClipboard(item.address)"
                @mouseover="currentIndex = index"
                @mouseleave="currentIndex = null"
              >
                <div
                  v-if="item.address"
                  class="address"
                >
                  {{ item.address }}
                </div>
                <div
                  v-if="!item.address"
                  class="address"
                >
                  -
                </div>

                <hover-text
                  v-if="currentIndex === index"
                  :option="item.address"
                />
              </td>
              <td class="table-cell table-balance" v-if="item.token">
                {{ amountToFixed(item.balanceAvailable, item.token) }}
              </td>
              <td class="table-cell table-balance" v-if="!item.token">
                {{ item.balanceUsdt }}
              </td>
              <td class="table-cell table-cell--edit">
                <div
                  v-if="item.address"
                  class="table-cell--edit_block"
                >
                  <div
                    v-if="isMobileDevice"
                    class="edit"
                    @click="copyTextToClipboard(item.address)"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4704_98977)">
                        <path
                          d="M6.66537 0.833984H16.6654C17.582 0.833984 18.332 1.58398 18.332 2.50065V14.1673H16.6654V2.50065H6.66537V0.833984ZM4.16537 4.16732H13.332C14.2487 4.16732 14.9987 4.91732 14.9987 5.83398V17.5007C14.9987 18.4173 14.2487 19.1673 13.332 19.1673H4.16537C3.2487 19.1673 2.4987 18.4173 2.4987 17.5007V5.83398C2.4987 4.91732 3.2487 4.16732 4.16537 4.16732ZM4.16537 17.5007H13.332V5.83398H4.16537V17.5007Z"
                          fill="#0A68F7"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4704_98977">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="matrix(-1 0 0 1 20 0)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <a
                    :href="item.marketUrl + item.address"
                    target="_blank"
                  >
                    <div class="edit">

                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_554_214)">
                          <path
                            d="M12.5026 18.3337H5.0026C4.08594 18.3337 3.33594 17.5837 3.33594 16.667V3.33366C3.33594 2.41699 4.08594 1.66699 5.0026 1.66699H11.6693L16.6693 6.66699V11.667H15.0026V7.50033H10.8359V3.33366H5.0026V16.667H12.5026V18.3337ZM15.8359 18.0503V16.1837L18.2943 18.642L19.4693 17.467L17.0109 15.0003H18.8776V13.3337H14.1693V18.0503H15.8359Z"
                            fill="#0A68F7"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_554_214">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                    </div>
                  </a>
                  <div
                    v-if="UserProfileData && UserProfileData.roles.includes('SuperAdmin')"
                    class="edit"
                    @click="mirrorWithdraw(item)"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_554_170)">
                        <path
                          d="M14.1641 5.83333L12.9891 7.00833L15.1391 9.16667H6.66406V10.8333H15.1391L12.9891 12.9833L14.1641 14.1667L18.3307 10L14.1641 5.83333ZM3.33073 4.16667H11.9974V2.5H3.33073C2.41406 2.5 1.66406 3.25 1.66406 4.16667V15.8333C1.66406 16.75 2.41406 17.5 3.33073 17.5H11.9974V15.8333H3.33073V4.16667Z"
                          fill="#0A68F7"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_554_170">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <!--        <no-data-block v-if="!nodasBalances && !isLoading" />-->
      </div>
    </div>
  </div>
  <CopyNotification
    v-if="isSuccessCopy"
    :text="copyText"
  />
</template>

<style scoped lang="scss">

.table_node-balances {
  max-width: 1122px;

  .table {
    margin-bottom: 0;

    &-block {
      &-table {
        padding-bottom: 3px;

        @media (max-width: 1079px) {
          overflow: auto;

          .table {
            width: 100%;
          }
        }
      }

      &-header {
        padding-bottom: 24px;
      }
    }

    &-first {
      padding-left: 32px;
      box-sizing: border-box;
      width: 168px;

      @media (max-width: 1079px) {
        padding-left: 16px;
        width: 104px;
      }

      .token {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        .icon {
          position: relative;
          width: 32px;
          height: 32px;

          &-network {
            position: absolute;
            bottom: -4px;
            left: 0;
            right: 0;
            margin: 0 auto;
            color: #FFF;
            text-align: center;
            font-family: 'Inter', sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
            border-radius: 3px;
            display: grid;
            place-items: center;
            width: 26px;
            height: 14px;

            &_trx {
              background: #0A68F7;
            }

            &_eth {
              background: #FB0000;
            }
          }
        }
      }
    }

    &-node_address {
      color: var(--black, #1B1A1C);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.2px;
      width: 670px;

      .address {
        box-sizing: border-box;
        width: inherit;
        padding: 0;
        overflow: hidden;
        position: relative;
        display: inline-block;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: all .3s ease-in-out;
      }

      &:hover {
        cursor: pointer;
        .address {
          color: #0A68F7;
        }
      }
    }

    .table-balance {
      text-align: right;
      width: 215px;
      @media (max-width: 1079px) {
        width: calc(100% - 224px);
      }
    }

    .table-cell--edit {
      width: 100px;
      text-align: center;

      @media (max-width: 1079px) {
        width: 132px;
      }

      &_block {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 4px;
      }
    }

    .edit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      background: rgba(10, 104, 247, 0.06);

      &:hover {
        cursor: pointer;
        background: rgba(10, 104, 247, 0.08);
      }
    }


    &-row {
      border-bottom: 1px solid #DFE0EB;

      .table-first {
        color: var(--black, #1B1A1C);
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

      }


    }

    &-row:last-child {
      border-bottom: transparent;
    }

    & > :not(:first-child) {
      .bottom {
        border-bottom: transparent;
      }

      border-top: 1px solid currentColor;
    }
  }
}

</style>
