<template>
  <div
    class="exchange-info-form-inputs-block"
  >
    <div
      v-for="(item, index) in data"
      :key="index"
      class="withdrawal"
    >
      <div
        v-if="item.amountUser"
        class="exchange-info-form-inputs-block_items"
      >
        <div class="exchange-info-form-inputs-block-item">
          <div class="desc">
            Amount
          </div>
          <div
            class="input"
          >
            {{ item.amountUser }}
          </div>
        </div>
        <div
          v-if="item.transactionId"
          class="exchange-info-form-inputs-block-item"
        >
          <div class="desc">
            Transaction id
          </div>
          <div
            v-clipboard:copy="item.transactionId"
            v-clipboard:success="onCopyAddress"
            class="input input-disabled"
          >
            {{ hashFormat(item.transactionId) }}
            <copy-icon-gray class="copyIcon" />
          </div>
        </div>
        <div
          v-if="item.txId"
          class="exchange-info-form-inputs-block-item"
        >
          <div class="desc">
            TxId
          </div>
          <div
            v-clipboard:copy="item.txId"
            v-clipboard:success="onCopyAddress"
            class="input input-disabled"
          >
            {{ hashFormat(item.txId) }}
            <copy-icon-gray class="copyIcon" />
          </div>
        </div>
      </div>
    </div>
    <CopyNotification
      v-if="isSuccessCopy"
      :text="copyText"
    />
  </div>
</template>

<script>
import CopyIconGray from "@/assets/img/icons/copyIconGray.vue";
import CopyNotification from "@/pages/notification/CopyNotification.vue";

export default {
  name: "ExchangeInfoWithdrawal",
  components: {CopyNotification, CopyIconGray},
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      copyText: '',
      isSuccessCopy: true,
    }
  },
  methods: {
    hashFormat(val) {
      if (val && val.length > 58) {
        return val.slice(0, 55) + '...'
      } else {
        return val
      }
    },
    onCopyAddress() {
      this.copyText = 'Copied to clipboard!';

      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
  }
}
</script>

<style scoped>

</style>
